<template>
    <div>
        <div id="Topology" ref="Topology"></div>
    </div>
</template>

<script>
import OrgChart from '@balkangraph/orgchart.js';
export default {
    name: 'Topology',
    props: ['hwgNumber'],
    data() {
        return {
            nodes: [
                {id: 1, name: 'Denny Curtis', title: 'CEO', img: 'https://cdn.balkan.app/shared/2.jpg'},
                {id: 2, pid: 1, name: 'Ashley Barnett', title: 'Sales Manager', img: 'https://cdn.balkan.app/shared/3.jpg'},
                {id: 3, pid: 1, name: 'Caden Ellison', title: 'Dev Manager', img: 'https://cdn.balkan.app/shared/4.jpg'},
                {id: 4, pid: 2, name: 'Elliot Patel', title: 'Sales', img: 'https://cdn.balkan.app/shared/5.jpg'},
                {id: 5, pid: 2, name: 'Lynn Hussain', title: 'Sales', img: 'https://cdn.balkan.app/shared/6.jpg'},
                {id: 6, pid: 3, name: 'Tanner May', title: 'Developer', img: 'https://cdn.balkan.app/shared/7.jpg'},
                {id: 7, pid: 3, name: 'Fran Parsons', title: 'Developer', img: 'https://cdn.balkan.app/shared/8.jpg'}
            ],
        };
    },
    methods: {
        oc(domEl, x) {
            this.$client.getHwgTopology(this.hwgNumber).then((res) => {
                let treeData = res.data;
                if (treeData) {
                    x = res.data;
                    x.map((it) => {
                        it.tags = ['normal'];
                    });
                    x[0].tags = ['gen'];
                    // x = this.nodes;
                    //拓扑图样式
                    //根节点
                    OrgChart.templates.root = Object.assign({}, OrgChart.templates.ana);
                    OrgChart.templates.root.node = '<rect x="0" y="0" height="{h}" width="{w}" fill="#2749A5" stroke-width="1" stroke="#aeaeae" rx="5" ry="5"></rect>';
                    OrgChart.templates.root.size = [550, 200];
                    OrgChart.templates.root.field_0 = '<text width="500" style="font-size: 18px;" fill="#cfcfcf" x="280" y="30" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_1 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="280" y="70" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_2 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="280" y="100" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_3 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="280" y="130" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_4 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="280" y="160" text-anchor="middle">{val}</text>';
                    //普通节点 4属性
                    OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.root);
                    OrgChart.templates.myTemplate.size = [500, 480];
                    OrgChart.templates.myTemplate.field_0 = '<text width="230" style="font-size: 18px;" fill="#cfcfcf" x="240" y="30" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_13 = '<text width="500" style="font-size: 14px;" fill="#cfcfcf" x="10" y="70">{val}</text>';
                    OrgChart.templates.myTemplate.field_1 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="10" y="100">{val}</text>';
                    OrgChart.templates.myTemplate.field_2 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="10" y="130">{val}</text>';
                    OrgChart.templates.myTemplate.field_3 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="10" y="160">{val}</text>';
                    OrgChart.templates.myTemplate.field_4 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="10" y="190">{val}</text>';
                    OrgChart.templates.myTemplate.field_5 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="220">{val}</text>';
                    OrgChart.templates.myTemplate.field_6 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="250">{val}</text>';
                    OrgChart.templates.myTemplate.field_7 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="280">{val}</text>';
                    OrgChart.templates.myTemplate.field_8 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="310">{val}</text>';
                    OrgChart.templates.myTemplate.field_9 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="340">{val}</text>';
                    OrgChart.templates.myTemplate.field_10 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="370">{val}</text>';
                    OrgChart.templates.myTemplate.field_11 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="400">{val}</text>';
                    OrgChart.templates.myTemplate.field_12 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="10" y="430">{val}</text>';
                    this.chart = new OrgChart(domEl, {
                        // template: 'derek',
                        // template: 'myTemplate',
                        nodes: x,
                        nodeBinding: {
                            field_0: 'name',
                            field_1: 'f1',
                            field_2: 'f2',
                            field_3: 'f3',
                            field_4: 'f4',
                            field_5: 'f5',
                            field_6: 'f6',
                            field_7: 'f7',
                            field_8: 'f8',
                            field_9: 'f9',
                            field_10: 'f10',
                            field_11: 'f11',
                            field_12: 'f12',
                            field_13: 'cabinetName',
                            // field_10: 'node',
                            // img_0: 'img'
                        },
                        tags: {
                            normal: {
                                template: 'myTemplate'
                            },
                            gen: {
                                template: 'root'
                            },
                        },
                        nodeMouseClick: null,
                        enableSearch: false
                    });
                }
            });
        }
    },
    mounted() {
        this.oc(this.$refs.Topology, this.nodes);
    }
};
</script>
